

@import url(//fonts.googleapis.com/css?family=Open+Sans);
/* CSS used here will be applied after bootstrap.css */

.App .navbar {
  background-color: white!important;
  text-align: center;
  position: relative;
  display: table-row;
  margin-left: 0;
  font-size: 14px;
  font-family: alternate-gothic-no-2-d, Helvetica, Arial, Tahoma, Verdana, sans-serif;
  width: 100%;
  
}


.App .Carouselsetting{
  text-align: center;
  padding-top: 30px;
  background:white;
  width:100%;
  height: 50%;
  
  
}
.Form{
  margin-top: 10px;
}
.homecontent{
  background-color: '#F7F7F7'
}
.App .carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: transform .5s ease-in-out;
  height: 500px;}
  .containerblog{
    display: block;
    text-align: left;
    height: 80%;
    
  }


.verticalLine {
  border-left: thin solid pink;
}

#navbar li {
border-right: 1px solid #ffffff;
}
#navbar li:last-child {
border-right: none
}

.nav-link {
  display: block;
  padding: .5rem 1rem;
}
a.nav-link  {
  color: black;
  text-decoration: none;
  background-color: transparent;
  
}
.affix {
  padding:0px;
  -webkit-transition:padding 0.2s linear;
  -moz-transition:padding 0.2s linear;  
  -o-transition:padding 0.2s linear;         
  transition:padding 0.2s linear;  

}

.affix-top {
  padding-top:15px;
  padding-bottom:15px;
  -webkit-transition:padding 0.5s linear;
  -moz-transition:padding 0.5s linear;  
  -o-transition:padding 0.5s linear;         
  transition:padding 0.5s linear;  
}



.homeHeadline {
  font-family: alternate-gothic-no-2-d, Helvetica, Arial, Tahoma, Verdana, sans-serif;
  font-size: 36px;
  line-height: 52px;
  text-transform: uppercase;
  color: #000;
  letter-spacing: 1px;
  text-align: center;
  margin: 0 0 20px 0;
  position: relative;
}



.title{
  font-size: 1.5em;
  font-family: Andale Mono, monospace	;
 
}
.content{
  text-align: justify;
}

h2 {
  width: 100%; 
  text-align: center; 
  border-bottom: 1px solid #000; 
  line-height: 0.1em;
  margin: 10px 0 20px; 
} 
 h2.span { 
  background:#fff; 
  padding:0 10px; 
}
h1.style-six {
  overflow: hidden;
  text-align: center;
}



.para{
  font-family: Courier New, monospace;

}
.image{
  padding-left:0;

}

/* 
hr.style-six {
  border: 0;
  height: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);

} */

.websitename{
  font-size: 1.5em;
  font-family: Snell Roundhand, cursive

}


.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 30px;
}


.container{
  background-color:white;
  height: 100%;

}
.carousel .slide {
  min-width: 50%;
  margin: 0;
  position: relative;
  text-align: center;
  background: #000;
}
.App-title {
  font-size: 1.2em;
  font-family: Courier New, monospace;
  
}

.App-intro {
  font-size: large;
}
.header{
  text-align: center;
}



