.footer{
    background-color: #DEDEDE;
    padding: 20px 0;
    height: 120px;
   
}
.pfw-social-footer a {
     font-size: 36px; 
    margin: 0 5px;
    height: 30px;
  
    color: #ee269b;
}
.footer-menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
}
.footer-menu ul li {
    display: inline;
    margin-right: 25px;
}
.footer .a{
    color: white;
}
.footer .container{
    background-color: #DEDEDE;  
}
.footer .fa {
    display: inline-block;
    font: normal normal normal 24px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 30px;
  
}
.footer-menu>div {
    border-bottom: none;
    padding-bottom: 0;
}
.footer-menu {
    font-size: 12px;
    text-transform: uppercase;
    margin-bottom: 30px;
    letter-spacing: 1px;
}

.footer .pfw-social-footer{
margin-bottom: 5px;
height: 50px;}